<template>
  <div id="orders__tag">
    <div
      @click.prevent="getDateAll"
      class="orders__tag"
      :class="tag[0] === `all` ? `active` : ``"
      data-value="all"
    >
      <div class="orders__tag--text" data-value="all">
        {{ getTabNames[0] }}
        <div class="tag__border"></div>
      </div>
      <div class="orders__tag--count" data-value="all">
        {{ countAll }}
      </div>
    </div>
    <div
      v-if="countToday > 0"
      @click.prevent="getToday"
      class="orders__tag"
      :class="tag[0] === `today` ? `active` : ``"
      data-value="today"
    >
      <div class="orders__tag--text" data-value="today">
        {{ getTabNames[1] }}
        <div class="tag__border"></div>
      </div>
      <div class="orders__tag--count" data-value="today">
        {{ countToday }}
      </div>
    </div>
    <div
      v-if="countTommorow > 0"
      @click.prevent="getTomorrow"
      class="orders__tag"
      :class="tag[0] === `tomorrow` ? `active` : ``"
      data-value="tomorrow"
    >
      <div class="orders__tag--text" data-value="tomorrow">
        {{ getTabNames[2] }}
        <div class="tag__border"></div>
      </div>
      <div class="orders__tag--count" data-value="tomorrow">
        {{ countTommorow }}
      </div>
    </div>
    <div class="count__orders">{{ countLimit }} ({{ count }})</div>
  </div>
</template>

<script>
export default {
  name: "TagBlock",
  props: {
    today: Number,
    tomorrow: Number,
    countAll: [Number, String],
    countToday: Number,
    countTommorow: Number,
    clearTab: Boolean,
    tabType: { type: String, default: "orders" },
    count: Number,
    countLimit: Number
  },
  data() {
    return {
      tag: ["all"],
      ordersTabName: ["Все заказы", "Заказы на сегодня", "Заказы на завтра"],
      paymentsTabName: ["Все счета", "Не оплаченные", "Оплаченные"]
    };
  },
  computed: {
    getTabNames() {
      let tabNames = [];
      this.tabType === "orders"
        ? (tabNames = [...this.ordersTabName])
        : (tabNames = [...this.paymentsTabName]);
      return tabNames;
    }
  },
  methods: {
    // Даты для табов
    getToday(event) {
      this.tag = [event.target.dataset.value];
      if (this.tabType === "orders") {
        this.$emit("changeFilterDate", [this.today, this.today]);
      } else {
        this.$emit("changeFilterPayd", 1);
      }
    },
    getTomorrow(event) {
      this.tag = [event.target.dataset.value];
      if (this.tabType === "orders") {
        this.$emit("changeFilterDate", [this.tomorrow, this.tomorrow]);
      } else {
        this.$emit("changeFilterPayd", 2);
      }
    },
    getDateAll(event) {
      this.tag = [event.target.dataset.value];
      if (this.tabType === "orders") {
        this.$emit("changeFilterDate", []);
      } else {
        this.$emit("changeFilterPayd", 0);
      }
    }
  }
};
</script>

<style lang="scss">
#orders__tag {
  @include flex;
  @include flex-content-start;
  font-size: 12px;
  position: relative;
}

.orders__tag {
  @include flex;
  @include flex-content-start;
  cursor: pointer;
  // margin: 40px 20px 0 10px;
  // Корректировка
  margin: 0 20px 0 10px;

  &:hover {
    .orders__tag--text {
      .tag__border {
        background: $color-basic;
        border-radius: 4px;
        height: 4px;
        width: 100%;
      }
    }
  }

  &.active {
    cursor: default;

    .orders__tag--text {
      color: $color-black;
      font-weight: 500;

      .tag__border {
        background: $color-grey-light;
        border-radius: 4px;
        height: 4px;
        width: 100%;
      }
    }

    .orders__tag--count {
      background: $color-grey-light;
      border: none;
    }
  }

  &--count {
    @include flex;
    background: $color-white;
    border: 1px solid $color-basic;
    border-radius: 50%;
    font-size: 10px;
    height: 30px;
    left: 4px;
    position: relative;
    top: -10px;
    width: 30px;
  }
}

.count__orders {
  position: absolute;
  right: 0;
}
</style>
