<template>
  <form>
    <div style="position: relative">
      <input
        id="filterSearch"
        v-model="filterSearch"
        @input="$emit(`changeFilterSearch`, $event.target.value)"
        type="text"
        placeholder="Поиск"
        autocomplete="off"
      />
      <label
        for="filterSearch"
        style="cursor: pointer; position: absolute; right: 26px; top: 9px"
      >
        <img
          v-if="!filterSearch"
          src="../../assets/images/search.svg"
          style="width: 15px; height: 15px"
          alt="Поиск"
        />
        <img
          v-else
          src="../../assets/images/close.svg"
          style="width: 9px; height: 9px"
          alt="Очистить"
          @click="clearFilter"
        />
      </label>
    </div>
    <date-picker
      v-model="filterDateRange"
      @change="$emit(`changeFilterDate`, filterDateRange)"
      type="date"
      valueType="timestamp"
      format="DD-MM-YYYY"
      range
      :placeholder="`01.01.${thisYear} ~ 31.12.${thisYear}`"
    ></date-picker>
    <div v-if="isFiltered" class="filters" ref="filters">
      <div
        @click="addFilters"
        class="filters__button"
        :class="isFilters ? `active` : ``"
      >
        Фильтры
        <img src="../../assets/images/filter-plus.svg" alt="Plus" />
      </div>
      <div class="filters__block" :class="isFilters ? `visible` : ``">
        <div
          v-for="item in filterArray"
          @mouseover="addFiltersSelect(item.id)"
          :key="item.id"
          class="filters__block--item"
          :class="filterArrayChoose === item.id ? `selected` : ``"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="filters__second" :class="isFiltersSelected ? `visible` : ``">
        <div v-if="filterArrayChoose === filterArray[0].id">
          <div
            v-for="item in filterStatusOrder"
            :key="item"
            class="filters__block--item form__checkbox"
          >
            <input
              :id="`filtersItem${item}`"
              type="checkbox"
              v-model="selectedStatusOrder"
              @change="$emit(`filterStatusOrder`, selectedStatusOrder)"
              :value="item"
              :checked="selectedStatusOrder.includes(item)"
            />
            <label :for="`filtersItem${item}`">{{ item }}</label>
          </div>
          <h4 @click="addFilterBtn">ПРИМЕНИТЬ</h4>
        </div>
        <div v-if="filterArrayChoose === filterArray[1].id">
          <div
            v-for="item in filterTypeOrder"
            :key="item"
            class="filters__block--item form__checkbox"
          >
            <input
              :id="`filtersItem${item}`"
              type="checkbox"
              v-model="selectedTypeOrder"
              @change="$emit(`filterTypeOrder`, selectedTypeOrder)"
              :value="item"
              :checked="selectedTypeOrder.includes(item)"
            />
            <label :for="`filtersItem${item}`">{{ item }}</label>
          </div>
          <h4 @click="addFilterBtn">ПРИМЕНИТЬ</h4>
        </div>
        <div v-if="filterArrayChoose === filterArray[2].id">
          <div
            v-for="item in filterTonnage"
            :key="item"
            class="filters__block--item form__checkbox"
          >
            <input
              :id="`filtersItem${item}`"
              type="checkbox"
              v-model="selectedTonnage"
              @change="$emit(`filterTonnage`, selectedTonnage)"
              :value="item"
              :checked="selectedTonnage.includes(item)"
            />
            <label :for="`filtersItem${item}`">{{ item }}</label>
          </div>
          <h4 @click="addFilterBtn">ПРИМЕНИТЬ</h4>
        </div>
        <div v-if="filterArrayChoose === filterArray[3].id">
          <div
            v-for="item in filterTypeCargo"
            :key="item"
            class="filters__block--item form__checkbox"
          >
            <input
              :id="`filtersItem${item}`"
              type="checkbox"
              v-model="selectedTypeCargo"
              @change="$emit(`filterTypeCargo`, selectedTypeCargo)"
              :value="item"
              :checked="selectedTypeCargo.includes(item)"
            />
            <label :for="`filtersItem${item}`">{{ item }}</label>
          </div>
          <h4 @click="addFilterBtn">ПРИМЕНИТЬ</h4>
        </div>
        <div v-if="filterArrayChoose === filterArray[4].id">
          <div
            v-for="item in filterPayment"
            :key="item"
            class="filters__block--item form__checkbox"
          >
            <input
              :id="`filtersItem${item}`"
              type="checkbox"
              v-model="selectedPayment"
              @change="$emit(`filterPayment`, selectedPayment)"
              :value="item"
              :checked="selectedPayment.includes(item)"
            />
            <label :for="`filtersItem${item}`">{{ item }}</label>
          </div>
          <h4 @click="addFilterBtn">ПРИМЕНИТЬ</h4>
        </div>
      </div>
      <div v-if="filterSelectedArrayChoose" class="filters__tabs">
        <div
          v-for="tab in selectedStatusOrder"
          :key="tab"
          class="filters__tabs--item"
          @click="clearSelectedStatusOrder(tab)"
        >
          {{ tab }}
          <img src="../../assets/images/close-o-grey.svg" alt="Закрыть" />
        </div>
        <div
          v-for="tab in selectedTypeOrder"
          :key="tab"
          class="filters__tabs--item"
          @click="clearSelectedTypeOrder(tab)"
        >
          {{ tab }}
          <img src="../../assets/images/close-o-grey.svg" alt="Закрыть" />
        </div>
        <div
          v-for="tab in selectedTonnage"
          :key="tab"
          class="filters__tabs--item"
          @click="clearSelectedTonnage(tab)"
        >
          {{ tab }}
          <img src="../../assets/images/close-o-grey.svg" alt="Закрыть" />
        </div>
        <div
          v-for="tab in selectedTypeCargo"
          :key="tab"
          class="filters__tabs--item"
          @click="clearSelectedTypeCargo(tab)"
        >
          {{ tab }}
          <img src="../../assets/images/close-o-grey.svg" alt="Закрыть" />
        </div>
        <div
          v-for="tab in selectedPayment"
          :key="tab"
          class="filters__tabs--item"
          @click="clearSelectedPayment(tab)"
        >
          {{ tab }}
          <img src="../../assets/images/close.svg" alt="Закрыть" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// Store
import { mapState } from "vuex";
// DatePicker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
// Data
import { filtersData } from "../../data/filters-data";

export default {
  name: "FilterBlock",
  props: {
    filterTypeOrder: Array,
    filterStatusOrder: Array,
    filterTonnage: Array,
    filterTypeCargo: Array,
    filterPayment: Array,
    isFiltered: { type: Boolean, default: false }
  },
  components: { DatePicker },
  data() {
    return {
      filterSearch: "",
      selectedTypeOrder: [],
      selectedStatusOrder: [],
      selectedTonnage: [],
      selectedTypeCargo: [],
      selectedPayment: [],
      filterArray: filtersData,
      filterSelectedArray: [],
      filterArrayChoose: null,
      isFilters: false,
      isFiltersSelected: false,
      filterDateRange: []
    };
  },
  mounted() {
    if (this.orderIdFromEmail) {
      this.filterSearch = this.orderIdFromEmail;
      this.$emit(`changeFilterSearch`, this.orderIdFromEmail);
    } else {
      this.filterSearch = "";
      this.$emit(`changeFilterSearch`, "");
    }
  },
  destroyed() {
    document.removeEventListener("click", this.dropHandler);
  },
  computed: {
    ...mapState(["orderIdFromEmail"]),

    thisYear() {
      return new Date().getFullYear();
    },

    filterSelectedArrayChoose() {
      return [
        ...this.selectedTypeOrder,
        ...this.selectedStatusOrder,
        ...this.selectedTonnage,
        ...this.selectedTypeCargo,
        ...this.selectedPayment
      ];
    }
  },
  methods: {
    addFilters() {
      this.isFilters = true;
      document.addEventListener("click", this.dropHandler);
    },

    addFiltersSelect(id) {
      this.filterSelectedArray = [];
      this.isFiltersSelected = false;
      this.filterArrayChoose = id;
      this.filterArray.map((item) => {
        if (item.id === id) {
          this.filterSelectedArray = item;
        }
      });
      if (this.filterSelectedArray) {
        this.isFiltersSelected = true;
      }
    },

    dropHandler(e) {
      let el = this.$refs.filters;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isFilters = false;
        this.isFiltersSelected = false;
        this.filterArrayChoose = null;
      }
    },

    addFilterBtn() {
      this.isFilters = false;
      this.isFiltersSelected = false;
    },

    clearFilter() {
      // this.filterDateRange = [];
      this.filterSearch = "";
      this.$emit("changeFilterSearch", "");
      // this.$emit("changeFilterDate", []);
    },
    clearSelectedTypeOrder(i) {
      return this.selectedTypeOrder.splice(i, 1);
    },
    clearSelectedStatusOrder(i) {
      return this.selectedStatusOrder.splice(i, 1);
    },
    clearSelectedTonnage(i) {
      return this.selectedTonnage.splice(i, 1);
    },
    clearSelectedTypeCargo(i) {
      return this.selectedTypeCargo.splice(i, 1);
    },
    clearSelectedPayment(i) {
      return this.selectedPayment.splice(i, 1);
    }
  }
};
</script>

<style lang="scss">
.filters {
  @include flex;
  @include flex-content-start;
  position: relative;

  &__block,
  &__second {
    display: none;
    background: $color-white;
    border: 1px solid $color-grey-light;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 6px 0;
    position: absolute;
    top: 0;
    left: 144px;
    width: 150px;
    z-index: 6;

    &.visible {
      display: block;
    }

    &--item {
      @include flex;
      @include flex-content-start;
      color: $color-black;
      cursor: pointer;
      font-size: 14px;
      min-width: 150px;
      padding: 8px 15px;

      &:hover,
      &.selected {
        background: $color-grey-light;
      }
    }
  }

  &__second {
    left: 302px;
    min-width: 190px;

    h4 {
      @include flex;
      color: $color-black;
      cursor: pointer;
      font-size: 14px;
      margin: 10px 0;
      text-align: center;
    }
  }

  &__button {
    @include flex;
    border: 1px solid $color-basic;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    height: 32px;
    width: 136px;

    &.active {
      background: $color-grey-light;
      img {
        @include rotation-right;
      }
    }

    &:hover {
      background: $color-grey-hover;
      img {
        @include rotation-right;
      }
    }

    img {
      @include rotation-left;
      margin-left: 10px;
    }
  }

  &__tabs {
    @include flex;
    @include flex-content-start;

    &--item {
      @include flex;
      @include flex-content-between;
      background: $color-grey-light;
      border-radius: 20px;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      margin-left: 10px;
      padding: 10px 16px 10px 10px;

      &:hover {
        background: $color-grey-hover;
        color: $color-grey-dark;
      }

      img {
        height: 9px;
        margin-left: 9px;
        width: 9px;
      }
    }
  }
}
</style>
